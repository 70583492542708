export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(app)": [~4,[2]],
		"/admin": [32,[3]],
		"/admin/commerce": [~33,[3]],
		"/admin/commerce/discounts/[discount_id]": [~34,[3]],
		"/admin/commerce/orders/[order_id]": [~35,[3]],
		"/admin/commerce/products/[product_id]": [~36,[3]],
		"/admin/content": [~37,[3]],
		"/admin/content/assets/[asset_id]": [~38,[3]],
		"/admin/content/edit/[page_id]": [~39,[3]],
		"/admin/users": [~40,[3]],
		"/admin/users/new": [42,[3]],
		"/admin/users/[user_id]": [~41,[3]],
		"/(app)/events": [7,[2]],
		"/(app)/events/[event_id]": [8,[2]],
		"/(app)/forgotpass": [9,[2]],
		"/(app)/forgotpass/confirm": [10,[2]],
		"/(app)/forgotpass/reset/[token]": [~11,[2]],
		"/(app)/interactive_diagrams": [12,[2]],
		"/(app)/login": [~13,[2]],
		"/(app)/logout": [~14,[2]],
		"/(app)/profile": [~15,[2]],
		"/(app)/research/knowledge_repository": [~16,[2]],
		"/(app)/research/knowledge_repository/edit/[entry_id]": [~17,[2]],
		"/(app)/research/knowledge_repository/keypoint_summary/[entry_id]": [~18,[2]],
		"/(app)/research/knowledge_repository/saved": [~19,[2]],
		"/(app)/signup": [20,[2]],
		"/(app)/sra": [21,[2]],
		"/(app)/sra/about/intent": [22,[2]],
		"/(app)/sra/assessment/new": [~24,[2]],
		"/(app)/sra/assessment/[sra_id]": [~23,[2]],
		"/(app)/sra/dashboard": [~25,[2]],
		"/(app)/store": [~26,[2]],
		"/(app)/store/cart": [~27,[2]],
		"/(app)/store/cart/checkout": [~28,[2]],
		"/(app)/store/orders": [~29,[2]],
		"/(app)/store/orders/[order_id]": [~30,[2]],
		"/(app)/store/products/[product_id]": [~31,[2]],
		"/(app)/[...url_slug]/edit": [~6,[2]],
		"/(app)/[...url_slug]": [~5,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';